import React, { useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { navigate } from 'gatsby'
import aisBizupLogo from '../../images/ads/AIS BIZUP.webp'

const pushToFirebase = async (payload, openmodal) => {
  const time = new Date()
  // const submitTime = Math.round(time.getTime() / 1000)
  firebase
    .database()
    .ref('create_at/' + time)
    .set({
      FullName: payload.fullName,
      CorpNmae: payload.corpName,
      Email: payload.email,
      Phone: payload.phoneNumber,
      PriviledgePhone: payload.priviledgePhone,
      PriviledgeCode: payload.priviledgeCode,
      Source: "AIS Bizup"
    })
    .then(() => {
      openmodal()
    })
}

const useStyles = makeStyles({
  styleInupt: {
    width: '70%',
    marginLeft: '8%',
    border: '2px solid #2CBBAA',
    borderRadius: '8px',
  },
  styleInuptFocus: {
    width: '70%',
    marginLeft: '8%',
    border: '2px solid red',
    borderRadius: '8px',
  },
})

export default function aisBizup(props) {
  const classes = useStyles()
  const overAllValidateList = [
    {
      receiveInfo: props.requestInfo.fullName,
      infoType: 'fullName',
    },
    {
      receiveInfo: props.requestInfo.corpName,
      infoType: 'corpName',
    },
    {
      receiveInfo: props.requestInfo.email,
      infoType: 'email',
    },
    {
      receiveInfo: props.requestInfo.priviledgeCode,
      infoType: 'priviledgeCode',
    },
    {
      receiveInfo: props.requestInfo.phoneNumber,
      infoType: 'phoneNumber',
    },
    {
      receiveInfo: props.requestInfo.priviledgePhone,
      infoType: 'priviledgePhone',
    },
  ]

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    navigate('/')
  }
  return (
    <div className="fade-in" lang={`${props.lang}`}>
      <div className="h-36"></div>
      <div className="max:container-requestDemo 2xl:container-requestDemo xl:container-requestDemo lg:container-requestDemo md:container-requestDemo container-90p mx-auto">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-2"></div>
          <div className="col-span-8">
            <div
              className=" text-body-header font-bold
                                text-size-30 max:text-size-60 2xl:text-size-55 xl:text-size-50 lg:text-size-45 md:text-size-35
                        "
            >
              {props.locale[`${props.lang}`].AAPPOINTAIS}
            </div>
            <div className="max:h-8 2xl:h-8 xl:h-8 lg:h-8 md:h-2"></div>
            <div
              className="text-body-header
                                text-size-18 max:text-size-40 2xl:text-size-37 xl:text-size-34 lg:text-size-27 md:text-size-20
                        "
            >
              {props.locale[`${props.lang}`].APPOINTDESCRIPTION}
            </div>
          </div>
          <div className="col-span-2"></div>
          <div className="col-span-12 max:h-8 2xl:h-8 xl:h-8 lg:h-8 md:h-2"></div>
          <div className="col-span-2"></div>
          <div className="col-span-8">
          <img
                className="container-1710 mx-auto"
                alt="aisBizupLogo"
                src={aisBizupLogo}
                width="80%"
              />
            <br/>
            <br/>
            <div
              className="text-body-header
                                text-size-16 max:text-size-34 2xl:text-size-30 xl:text-size-27 lg:text-size-24 md:text-size-18
                                leading-6 max:leading-10  2xl:leading-10 xl:leading-8 lg:leading-8 md:leading-6
                        "
            >
              {props.locale[`${props.lang}`].AAPPOINTAISPROMO}
            </div>
          </div>
          <div className="col-span-2"></div>
        </div>
        <div className="col-span-12 max:h-8 2xl:h-8 xl:h-8 lg:h-8 md:h-2"></div>
        <div className="w-90p max:w-80p 2xl:w-80p xl:w-80p lg:w-80p md:w-80p mx-auto">
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].FIRST_LAST_NAME}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={fullName =>
              props.validateInfo(fullName.target.value, 'fullName')
            }
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.fullName ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {
                props.locale[`${props.lang}`][
                  `${props.requestInfoError.fullName}`
                ]
              }
            </div>
          )}
          <br />
          <br />
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].CORP_NAME_AIS}{' '}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={corpName =>
              props.validateInfo(corpName.target.value, 'corpName')
            }
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.corpName ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {
                props.locale[`${props.lang}`][
                  `${props.requestInfoError.corpName}`
                ]
              }
            </div>
          )}
          <br />
          <br />
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].E_MAIL}{' '}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={email => props.validateInfo(email.target.value, 'email')}
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.email ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {props.locale[`${props.lang}`][`${props.requestInfoError.email}`]}
            </div>
          )}
          <br />
          <br />
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].CONTACT_NO}{' '}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={phoneNumber => {
              phoneNumber.target.value = phoneNumber.target.value
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1') //recieve only number
              props.validateInfo(phoneNumber.target.value, 'phoneNumber')
            }}
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.phoneNumber ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {
                props.locale[`${props.lang}`][
                  `${props.requestInfoError.phoneNumber}`
                ]
              }
            </div>
          )}
          <br />
          <br />
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].CONTACT_PRIVILEGE}{' '}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={priviledgePhone => {
                priviledgePhone.target.value = priviledgePhone.target.value
                .replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1') //recieve only number
              props.validateInfo(priviledgePhone.target.value, 'priviledgePhone')
            }}
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.priviledgePhone ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {
                props.locale[`${props.lang}`][
                  `${props.requestInfoError.priviledgePhone}`
                ]
              }
            </div>
          )}
          <br />
          <br />
          <div
            className=" text-body-content-green font-medium
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                            text-size-16 max:text-size-30 2xl:text-size-27 xl:text-size-26 lg:text-size-24 md:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].PRIVILEGE_CODE}{' '}
            <span className="text-red">*</span>
          </div>
          <div className="h-2 max:h-6 2xl:h-6 xl:h-6 lg:h-6 md:h-4"></div>
          <div className="inline-block w-5p max:w-0 2xl:w-0 xl:w-0 lg:w-0 md:w-0"></div>
          <TextField
            onChange={priviledgeCode =>
              props.validateInfo(priviledgeCode.target.value, 'priviledgeCode')
            }
            variant="outlined"
            classes={{
              root: classes.styleInupt,
              focused: classes.styleInuptFocus,
            }}
          ></TextField>
          {props.requestInfoStatus.priviledgeCode ? null : (
            <div
              className="
                            text-red text-size-20 font-medium
                            leading-8
                            ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                        "
            >
              {
                props.locale[`${props.lang}`][
                  `${props.requestInfoError.priviledgeCode}`
                ]
              }
            </div>
          )}
          <div className="h-12 max:h-28 2xl:h-24 xl:h-20 lg:h-16 md:h-14"></div>
          <button
            className="bg-menu-button rounded-full w-44p text-white hover:bg-menu-button-hover cursor-pointer
                                ml-13p max:ml-8p 2xl:ml-8p xl:ml-8p lg:ml-8p md:ml-8p
                                h-14 max:h-20 2xl:h-20 xl:h-16 lg:h-16 md:h-16
                                text-size-14 max:text-size-25 2xl:text-size-25 xl:text-size-23 lg:text-size-20 md:text-size-18"
            onClick={async () => {
              let submitResult = await props.overAllValidate(
                overAllValidateList
              )
              if (submitResult) {
                pushToFirebase(props.requestInfo, handleOpen)
              }
            }}
          >
            {props.locale[`${props.lang}`].SUBMIT_REQUEST}
          </button>
          <div className="h-12 max:h-28 2xl:h-20 xl:h-20 lg:h-16 md:h-14"></div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div
          className="bg-white mx-auto my-auto mt-20p p-4p text-center text-body-content-green font-bold
                        w-86p max:w-60p 2xl:w-60p xl:w-60p lg:w-60p md:w-60p sm:w-72p
                "
        >
          <div
            className="
                        text-size-16 max:text-size-35 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
                    "
          >
            {props.locale[`${props.lang}`].GREETING_1}
          </div>
          <div className="h-2 max:h-10 2xl:h-8 xl:h-6 lg:h-4"></div>
          <div
            className="
                        text-size-13 max:text-size-22 2xl:text-size-22 xl:text-size-20 lg:text-size-18 md:text-size-15 sm:text-size-14
                    "
          >
            {props.locale[`${props.lang}`].GREETING_2}
          </div>
        </div>
      </Modal>
    </div>
  )
}
