import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import appAction from '../state/app/actions'
import View from '../components/presentation/AAppointBizup'

class aisBizup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            requestInfo: {
                fullName: '',
                corpName: '',
                phoneNumber: '',
                priviledgePhone: '',
                email: '',
                priviledgeCode: ''
            },
            requestInfoStatus: {
                fullName: true,
                corpName: true,
                phoneNumber: true,
                priviledgePhone: true,
                email: true,
                priviledgeCode: true
            },
            requestInfoError: {
                fullName: '',
                corpName: '',
                phoneNumber: '',
                priviledgePhone: '',
                email: '',
                priviledgeCode: ''
            },
        }
        this.toggleLang = this.toggleLang.bind(this)
        this.validateInfo = this.validateInfo.bind(this)
        this.overAllValidate = this.overAllValidate.bind(this)
        this.sleep = this.sleep.bind(this)
    }
    toggleLang = () => {
        if (this.props.app.lang === 'EN') {
            this.props.onChangeLanguage('TH')
        } else {
            this.props.onChangeLanguage('EN')
        }
        return this.props.app.lang
    }

    validateInfo = (receiveInfo, infoType) => {
        // Fullname, CorpName and Business Name validation
        if (infoType === 'fullName' || infoType === 'corpName' || infoType === 'priviledgeCode') {
            if (receiveInfo.length > 0) {
                this.setState(prevState => (
                    {
                        requestInfo: { ...prevState.requestInfo, [infoType]: receiveInfo },
                        requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: true }
                    }
                ))
                return true
            } else {
                if (infoType === 'fullName') {
                    this.setState(prevState => (
                        {
                            requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false },
                            requestInfoError: { ...prevState.requestInfoError, [infoType]: 'FILL_FULL_NAME' }
                        }
                    ))
                    return false
                } else {
                    if (infoType === 'corpName') {
                        this.setState(prevState => (
                            {
                                requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false },
                                requestInfoError: { ...prevState.requestInfoError, [infoType]: 'FILL_CORPNAME' }
                            }
                        ))
                        return false
                    } else {
                        this.setState(prevState => (
                            {
                                requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false },
                                requestInfoError: { ...prevState.requestInfoError, [infoType]: 'FILL_PRIVILEDGECODE' }
                            }
                        ))
                        return false
                    }
                }
            }
        }
        // Fistname and Last name validation
        // Mobile Number validation
        if (infoType === 'phoneNumber' || infoType === 'priviledgePhone') {
            console.log(infoType)
            if ((receiveInfo.length === 9) || (receiveInfo.length === 10)) {
                if (receiveInfo.slice(0, 1) === '0') {
                    this.setState(prevState => (
                        {
                            requestInfo: { ...prevState.requestInfo, [infoType]: receiveInfo },
                            requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: true }
                        }
                    ))
                    return true
                } else {
                    this.setState(prevState => (
                        {
                            requestInfoError: { ...prevState.requestInfoError, [infoType]: "INVALID_NUM" },
                            requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false }
                        }
                    ))
                    return false
                }
            } else {
                if (receiveInfo.length > 10) {
                    this.setState(prevState => (
                        {
                            requestInfoError: { ...prevState.requestInfoError, [infoType]: "EXCEED_NUM" },
                            requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false }
                        }
                    ))
                    return false
                }
                if (receiveInfo.length < 9) {
                    this.setState(prevState => (
                        {
                            requestInfoError: { ...prevState.requestInfoError, [infoType]: "UNDER_NUM" },
                            requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false }
                        }
                    ))
                    return false
                }
            }
        }
        // Mobile Number validation
        // Email Validation
        if (infoType === 'email') {
            if (
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(receiveInfo)
            ) {
                this.setState(prevState => (
                    {
                        requestInfo: { ...prevState.requestInfo, [infoType]: receiveInfo },
                        requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: true }
                    }
                ))
                return true
            } else {
                this.setState(prevState => (
                    {
                        requestInfoError: { ...prevState.requestInfoError, [infoType]: "INVALID_MAIL" },
                        requestInfoStatus: { ...prevState.requestInfoStatus, [infoType]: false }
                    }
                ))
                return false
            }
        }
        // Email Validation
    }

    overAllValidate = async (validateList) => {
        let overallStatus = true
        for (let index = 0; index < validateList.length; index++) {
            console.log(validateList[index].receiveInfo, '    ', validateList[index].infoType)
            overallStatus = await this.validateInfo(validateList[index].receiveInfo, validateList[index].infoType) && overallStatus
        }
        console.log(overallStatus)
        return overallStatus
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    render() {
        return (
            <Layout
                locale={this.props.app.locale}
                lang={this.props.app.lang}
                toggleLang={this.toggleLang}
            >
                <SEO title="Contact Sales" />
                <View
                    locale={this.props.app.locale}
                    lang={this.props.app.lang}
                    validateInfo={this.validateInfo}
                    overAllValidate={this.overAllValidate}
                    requestInfo={this.state.requestInfo}
                    requestInfoStatus={this.state.requestInfoStatus}
                    requestInfoError={this.state.requestInfoError}
                />
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        app: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            onChangeLanguage: appAction.onChangeLanguage
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(aisBizup)
